/* home */
import { home } from ".";

function getHomeMyInfo() {
  return home.get("my-info");
}

/* 상담 */
const consult = "/consult/";

//확정
const confirm = "confirmed/";

//대기
const req = "requested/";

//상담 확정 수
function getHomeConfirmCnt() {
  return home.get(`${consult}${confirm}count`);
}

//상담 대기 수
function getHomeReqCnt() {
  return home.get(`${consult}${req}count`);
}

/* 공지사항 */
function getHomeNotice(query) {
  return home.get(`notice${query}`);
}

/* 스케줄 */
const schedule = "/schedule/date";

function getHomeScheduleMonth(query) {
  return home.get(`${schedule}${query}`);
}

function getHomeScheduleDate(date) {
  return home.get(`${schedule}/${date}`);
}

//줌
function getHomeZoom(booking_sid) {
  return home.get(`zoom/${booking_sid}`);
}

//줌, 메타버스 입장시간 기록
function setHomeZoomTime(booking_sid) {
  console.log(booking_sid);
  return home.post(`booking/${booking_sid}/date-time`);
}

//메타버스
function getHomeMetaverse(booking_sid) {
  return home.get(`metaverse/${booking_sid}`);
}

export {
  getHomeNotice,
  getHomeScheduleMonth,
  getHomeScheduleDate,
  getHomeConfirmCnt,
  getHomeReqCnt,
  getHomeMyInfo,
  getHomeZoom,
  getHomeMetaverse,
  setHomeZoomTime,
};
