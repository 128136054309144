import axios from "axios";
import store from "@/store/index";
import {
  getAuthFromLocalStorage,
  getRefreshLocalStorage,
  saveAuthToLocalStorage,
} from "@/utils/localStorage";
import { DeviceUUID } from "device-uuid";
import router from "@/router/router";

axios.defaults.withCredentials = true;
let isRefreshing = false;

export function setInterceptors(instance) {
  instance.interceptors.request.use(
    function (config) {
      const token = getAuthFromLocalStorage();
      if (token) {
        config.headers["auth_session"] = token;
        config.headers["time_zone"] =
          Intl.DateTimeFormat().resolvedOptions().timeZone;
        config.headers["service_lang"] = store.state.lang;
      }
      config.headers = config.headers ?? {};
      if (config.data instanceof FormData) {
        config.headers["Content-Type"] = "multipart/form-data";
      } else {
        config.headers["Content-Type"] = "application/json";
      }
      config.headers.Authorization = store.state.token;
      config.headers["Access-Control-Allow-Origin"] = "*";
      config.timeout = 12000;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      const token = getAuthFromLocalStorage();
      const refresh_yn = getRefreshLocalStorage() === "y";
      //자동로그인
      if (
        error.response.data.code === -812 &&
        !originalRequest._retry &&
        refresh_yn
      ) {
        if (!isRefreshing) {
          isRefreshing = true;
          const uuid = new DeviceUUID().get();
          try {
            const { data } = await axios.post(
              `${process.env.VUE_APP_API_URL}refresh`,
              {
                device_id: uuid,
              }
            );
            saveAuthToLocalStorage(data.session_key);
            originalRequest.headers["auth_session"] = token;
            return instance(originalRequest);
          } catch (error) {
            isRefreshing = true;
            store.state.reLogin = true;
            return Promise.reject(error);
          } finally {
            router.go(0);
          }
        }
      } else if (
        error.code === "ECONNABORTED" ||
        error.response.data.code === -801 ||
        error.response.data.code === -802 ||
        error.response.data.code === -803 ||
        error.response.data.code === -804 ||
        error.response.data.code === -805 ||
        (error.response.data.code === -812 && !refresh_yn)
      ) {
        store.state.error = error.response.data.code;
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
  return instance;
}
