import { checkCode, sendCode } from "@/api/email";
import { loginUser, registerUser } from "@/api/auth";
import {
  getConsultList,
  getConsultListDetail,
  getPatientList,
  getReject,
  getZoomMemo,
  postZoomMemo,
  putZoomMemo,
  setConsult,
  setManager,
  setReject,
} from "@/api/consult";
import {
  saveAuthToLocalStorage,
  saveUserToLocalStorage,
} from "@/utils/localStorage";
import {
  getHomeConfirmCnt,
  getHomeMetaverse,
  getHomeMyInfo,
  getHomeReqCnt,
  getHomeScheduleMonth,
  getHomeZoom,
  setHomeZoomTime,
} from "@/api/home";
import {
  getBoardList,
  getBoardListDetail,
  getMyProfile,
  logout,
  putMyProfile,
} from "@/api";
import {
  getCalender,
  getCalenderDetail,
  getSkedTemp,
  setCalenderDetail,
  setSkedTemp,
} from "@/api/schedule";
import { pwConfirm, pwFixed } from "@/api/common";
import {
  getZoomConnect,
  deleteZoomUserId,
  getZoomUserId,
  getZoomWebSdk,
  setZoomEnd,
  getZoomStarted,
} from "@/api/zoom";

export default {
  //로그인
  async LOGIN({ commit }, userData) {
    const { data } = await loginUser(userData);
    if (data.sessionKey) {
      commit("setToken", data.sessionKey);
      commit("setUsername", data.physician_id);
      saveAuthToLocalStorage(data.sessionKey);
      saveUserToLocalStorage(data.physician_id);
      return data;
    }
  },

  //로그아웃
  async LOGOUT({ commit }) {
    await logout();
    commit("setToken", null);
    commit("setUsername", null);
  },

  //회원가입
  async REGISTER(state, userData) {
    const { data } = await registerUser(userData);
    return data;
  },

  //email
  async EMAIL_CHACK_CODE(state, query) {
    const { data } = await checkCode(query);
    return data;
  },

  async EMAIL_SEND_CODE(state, email) {
    const { data } = await sendCode(email);

    return data;
  },

  //내정보 조회
  // eslint-disable-next-line
  async GET_MY_PROFILE(state) {
    const { data } = await getMyProfile();
    return data;
  },

  //내정보 수정
  // eslint-disable-next-line
  async PUT_MY_PROFILE(state, reqData) {
    const { data } = await putMyProfile(reqData);
    return data;
  },

  //내정보 수정 - 비밀번호 확인
  async PW_CONFIRM(state, pw) {
    const { data } = await pwConfirm(pw);
    return data;
  },

  //내정보 수정 - 비밀번호 변경
  async PW_FIXED(state, pw) {
    const { data } = await pwFixed(pw);
    return data;
  },

  /* home */
  //zoom
  async GET_ZOOM(state, booking_sid) {
    const { data } = await getHomeZoom(booking_sid);
    return data;
  },

  //web sdk
  async GET_ZOOM_WEB_SDK(state, booking_sid) {
    const { data } = await getZoomWebSdk(booking_sid);
    return data;
  },

  //metaverse
  async GET_METAVERSE(state, booking_sid) {
    const { data } = await getHomeMetaverse(booking_sid);
    return data;
  },

  //home - 상담 확정
  async HOME_CONSULT_CONFIRMED_CONUT() {
    const { data } = await getHomeConfirmCnt();
    return data.confirmed_count;
  },

  //home - 상담 대기
  async HOME_CONSULT_REQ_CONUT() {
    const { data } = await getHomeReqCnt();
    return data.requested_count;
  },
  async HOME_MY_INFO({ commit }) {
    const { data } = await getHomeMyInfo();
    if (data) commit("getHomeMyInfo", data);
    return data;
  },

  async HOME_GET_SCHEDULE_MONTH(state, query) {
    const { data } = await getHomeScheduleMonth(query);
    return data;
  },

  //Home 공지사항
  //고객센터 - 공지사항, FAQ
  async BOARD_LIST(state, req) {
    const { data } = await getBoardList(req);
    return data;
  },

  //zoom 연동
  async GET_ZOOM_CONNECT() {
    const { data } = await getZoomConnect();
    return data;
  },

  //zoom user id 조회
  async GET_ZOOM_USER_ID() {
    const { data } = await getZoomUserId();
    return data;
  },

  //zoom 연동 해제
  async DELETE_ZOOM_USER_ID() {
    const { data } = await deleteZoomUserId();
    return data;
  },

  //줌, 메타버스 입장시간 기록
  async SET_HOME_JOIN_TIME(state, id) {
    console.log(id, "booking_sid");
    const { data } = await setHomeZoomTime(id);
    return data;
  },

  //공지사항, FAQ 상세보기
  async BOARD_LIST_DETAIL(state, id) {
    const { data } = await getBoardListDetail(id);
    return data;
  },

  //상담관리
  async CONSULT_LIST(state, query) {
    const { data } = await getConsultList(query);
    return data;
  },

  //상담관리상세
  async CONSULT_LIST_DTAIL(state, id) {
    const { data } = await getConsultListDetail(id);
    return data;
  },

  //상담관리 - 확정
  async SET_CONSULT(state, id) {
    const { data } = await setConsult(id);
    return data;
  },

  //상담매니저 등록
  async SET_MANAGER_EMAIL(state, obj) {
    const { data } = await setManager(obj);
    return data;
  },

  //환자 상담 조회
  async GET_PATIENT_LIST(state, query) {
    const { data } = await getPatientList(query);
    return data;
  },

  //상담 반려 사유
  async SET_REJECT(state, query) {
    const { data } = await setReject(query);
    return data;
  },

  //상담 반려 사유 조회
  async GET_REJECT(state, id) {
    const { data } = await getReject(id);
    return data;
  },

  //상담 메모 조회
  async GET_ZOOM_MEMO(state, obj) {
    const { data } = await getZoomMemo(obj);
    return data;
  },
  //상담 메모 작성
  async POST_ZOOM_MEMO(state, obj) {
    const { data } = await postZoomMemo(obj);
    return data;
  },
  //상담 메모 수정
  async PUT_ZOOM_MEMO(state, obj) {
    const { data } = await putZoomMemo(obj);
    return data;
  },

  //스케줄 관리
  //스케줄 템플릿 조회
  async SKEDTEMP_LIST() {
    const { data } = await getSkedTemp();
    return data;
  },

  //스케줄 템플릿 저장
  async SET_SKEDTEMP_LIST(state, sked) {
    const { data } = await setSkedTemp(sked);
    return data;
  },

  //예외 일자 조회
  async GET_SKED_DATE(state, date) {
    const { data } = await getCalender(date);
    return data;
  },

  //예외 일자 상세 조회
  async GET_SKED_DATE_DETAIL(state, date) {
    const { data } = await getCalenderDetail(date);
    return data;
  },

  // 예외 일자 수정
  async SET_SKED_DATE_DETAIL(state, id) {
    const { data } = await setCalenderDetail(id);
    return data;
  },

  //zoom 진행중 미팅 조회
  async GET_ZOOM_STARTED(state, query) {
    const { data } = await getZoomStarted(query);
    return data;
  },

  //zoom 종료
  async SET_ZOOM_END(state, id) {
    const { data } = await setZoomEnd(id);
    return data;
  },
};
