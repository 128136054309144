import axios from "axios";
import { setInterceptors } from "./common/interceptors";

function creacteInstance() {
  return axios.create({
    baseURL: process.env.VUE_APP_API_URL,
  });
}

function creacteInstanceWithAuth(url) {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}${url}`,
  });
  return setInterceptors(instance);
}

function creacteCommonWithAuth(url) {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_COMMON_API_URL}${url}`,
  });
  return setInterceptors(instance);
}

function creacteBoardWithAuth(url) {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_BOARD_API_URL}${url}`,
  });
  return setInterceptors(instance);
}

function creacteZoomWithAuth(url) {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_ZOOM_API_URL}${url}`,
  });
  return setInterceptors(instance);
}

//register, login
export const instance = creacteInstance();

//email
export const emailCode = creacteCommonWithAuth("email");

//로그아웃
export const logout = () => {
  return creacteInstanceWithAuth("logout").post();
};

//의사회원 정보 조회
export const getMyProfile = () => {
  return creacteInstanceWithAuth("").get();
};

//의사회원 정보 수정
export const putMyProfile = (data) => {
  return creacteInstanceWithAuth("modify").post("", data);
};

//비밀번호 확인
export const pw = creacteInstanceWithAuth("pw");

//home
export const home = creacteInstanceWithAuth("home");

//consult - 상담관리
export const consult = creacteInstanceWithAuth("consult");

//schedule - 스케줄 관리
export const sked = creacteInstanceWithAuth("schedule");

//cs - 고객센터
export const cs = creacteInstanceWithAuth("cs");

//zoom
export const zoom = creacteZoomWithAuth("");

//게시판 목록
export const getBoardList = (req) => {
  return creacteBoardWithAuth("").post("list_board.seize", req);
};

//게시판 상세보기
export const getBoardListDetail = (id) => {
  return creacteBoardWithAuth("").post("load_board.seize", id);
};
