<template>
  <div class="btnWrap">
    <router-link
      v-if="link"
      :to="link"
      class="btn"
      :class="className"
      :style="btnStyles"
    >
      {{ text }}
    </router-link>
    <button
      v-else
      class="btn"
      :style="btnStyles"
      :class="className"
      @click.prevent="eventSet"
    >
      {{ text }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    link: String,
    className: String,
  },
  methods: {
    eventSet() {
      this.$emit("eve");
    },
  },
};
</script>

<style lang="scss">
.btnWrap {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    border-radius: 32px;
    border: 1px solid $mainColor;
    @include boxTxtStyle($fw: 700, $fz: 16px, $lh: 21px, $c: #fff);
    outline: none;
    cursor: pointer;
    background-color: $mainColor;

    &.type0 {
      border-color: $mainColor;
      background-color: #fff;
      color: $mainColor;
    }

    &.type1 {
      border-color: #7a7a7a;
      background-color: #fff;
      color: #7a7a7a;
    }

    &.type2 {
      border-color: #fc6969;
      background-color: #fc6969;
      color: #fff;
    }
  }
}

.mb0 {
  margin-bottom: 0;
}
</style>
