<template>
  <span class="icon">
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9 23.6C22.3322 23.6 23.7057 23.0311 24.7184 22.0184C25.731 21.0057 26.3 19.6322 26.3 18.2C26.3 16.7679 25.731 15.3944 24.7184 14.3817C23.7057 13.369 22.3322 12.8 20.9 12.8C19.4678 12.8 18.0943 13.369 17.0816 14.3817C16.0689 15.3944 15.5 16.7679 15.5 18.2C15.5 19.6322 16.0689 21.0057 17.0816 22.0184C18.0943 23.0311 19.4678 23.6 20.9 23.6Z"
        stroke="#199A8E"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.6999 2H20.9C7.39999 2 2 7.4 2 20.9V37.1C2 50.6 7.39999 56 20.9 56H37.0999C50.5999 56 55.9999 50.6 55.9999 37.1V23.6"
        stroke="#199A8E"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M45.2 18.2V2L50.6 7.4M45.2 2L39.8 7.4M3.80908 47.765L17.1201 38.828C19.253 37.397 22.331 37.559 24.248 39.206L25.139 39.989C27.245 41.798 30.647 41.798 32.753 39.989L43.985 30.35C46.091 28.541 49.493 28.541 51.599 30.35L56 34.13"
        stroke="#199A8E"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style></style>
