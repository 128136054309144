import { instance, pw } from ".";

//진료과목 조회
function getSubject(query) {
  return instance.get(`subject${query}`);
}

//비밀번호 조회
function pwConfirm(password) {
  return pw.post("", password);
}

//비밀번호 변경
function pwFixed(password) {
  return pw.put("/fg", password);
}

export { getSubject, pwConfirm, pwFixed };
