<template>
  <div
    class="popup"
    :class="(type === 'type3' && 'db') || (type === 'type4' && 'db2')"
  >
    <div class="popupBg" @click="close"></div>

    <div :class="`popupContent ${type}`">
      <!-- default popup -->
      <div
        v-if="
          type === 'type1' ||
          type === 'imageFail' ||
          type === 'agreeFail' ||
          type === 'search'
        "
        class="type1"
      >
        <!-- 상담 불가능 icon-->
        <sked-impossible-icon class="icon" v-if="sked === 'skedI'" />
        <!-- 상담 가능 icon-->
        <sked-possible-icon class="icon" v-if="sked === 'skedP'" />
        <!-- 성공 -->
        <check-icon
          v-if="
            !sked && !fail && type !== 'imageFail' && type !== 'search' && !zoom
          "
          class="icon"
          :class="type === 'agreeFail' && 'fail'"
          width="41"
          height="31"
        />
        <!-- zoom icon-->
        <zoom-unlinked-icon class="icon" v-if="zoom" />
        <!-- 실패 -->
        <fail-icon v-if="!sked && fail" />
        <!-- 이미지 확장자 vaildation -->
        <image-file-fail-icon v-if="type === 'imageFail'" />
        <!-- 검색어 입력 error popup -->
        <search-fail-icon v-if="type === 'search'" />

        <!-- contents -->
        <strong
          class="title"
          :class="type === 'imageFail' && 'imageFailTitle'"
          >{{ title }}</strong
        >
        <p class="txt" v-if="type !== 'imageFail' && !vhtml">{{ text }}</p>
        <p
          class="txt"
          v-html="text"
          v-if="type === 'imageFail' && !vhtml"
          :class="type === 'imageFail' && 'imageFailTxt'"
        ></p>
        <p class="txt html" v-if="vhtml" v-html="text"></p>
        <round-btn
          v-if="sked || type === 'search'"
          :text="btnTxt"
          @click="close"
        />
        <round-btn v-else :link="link" :text="btnTxt" @click="close" />
      </div>

      <!-- contents popup -->
      <div v-if="type === 'type2' || type === 'imageFail'" class="type2">
        <div class="wrap" :class="bgn && 'bgn'">
          <slot name="content"></slot>
        </div>
      </div>

      <!-- notice contents popup -->
      <div class="contentPopup" v-if="type === 'type3' || type === 'type4'">
        <slot name="content"></slot>
      </div>
      <slot name="content" v-if="type === 'type5'"></slot>
    </div>
  </div>
</template>

<script>
import RoundBtn from "../btn/RoundBtn.vue";
import CheckIcon from "../icon/input/CheckIcon.vue";
import FailIcon from "../icon/popup/FailIcon.vue";
import ImageFileFailIcon from "../icon/popup/ImageFileFailIcon.vue";
import SearchFailIcon from "../icon/popup/SearchFailIcon.vue";
import ZoomUnlinkedIcon from "../icon/popup/ZoomUnlinkedIcon.vue";
import SkedImpossibleIcon from "../icon/sked/SkedImpossibleIcon.vue";
import SkedPossibleIcon from "../icon/sked/SkedPossibleIcon.vue";
export default {
  components: {
    CheckIcon,
    RoundBtn,
    SkedImpossibleIcon,
    SkedPossibleIcon,
    SearchFailIcon,
    FailIcon,
    ImageFileFailIcon,
    ZoomUnlinkedIcon,
  },
  props: {
    type: {
      type: String,
      default: "type1",
    },
    title: String,
    text: String,
    btnTxt: String,
    link: {
      type: String,
      default: "/",
    },
    sked: String,
    bgn: Boolean,
    fail: Boolean,
    vhtml: Boolean,
    zoom: Boolean,
  },
  mounted() {
    document.querySelector("html").style.overflowY = "hidden";
    document.body.style.overflowY = "hidden";
    if (document.querySelector(".mainMenu")) {
      document.querySelector(".quickmenu").style.zIndex = 99;
    }
    if (document.querySelector("header")) {
      document.querySelector("header").style.zIndex = 100;
    } else if (
      !document.querySelector(".mainMenu") &&
      document.querySelector(".quickmenu") &&
      !document.querySelector(".popupBtnWrap")
    ) {
      document.querySelector(".quickmenu").style.zIndex = 103;
    } else if (
      document.querySelector(".quickmenu") &&
      document.querySelector(".popupBtnWrap")
    ) {
      document.querySelector(".quickmenu").style.zIndex = 102;
    } else if (document.querySelector(".squareMultipleWrap")) {
      document.querySelector(".squareMultipleWrap").style.zIndex = 100;
    } else if (document.querySelector(".squareBtn")) {
      document.querySelector(".squareBtn").style.zIndex = 100;
    }
  },
  beforeUnmount() {
    if (document.querySelector("header")) {
      document.querySelector("header").style.zIndex = 101;
    } else if (document.querySelector(".squareMultipleWrap")) {
      document.querySelector(".squareMultipleWrap").style.zIndex = 100;
    } else if (document.querySelector(".squareBtn")) {
      document.querySelector(".squareBtn").style.zIndex = 102;
    } else if (document.querySelector(".mainMenu")) {
      document.querySelector(".squareBtn").style.zIndex = 100;
    }
    document.querySelector("html").style.overflowY = "auto";
    document.body.style.overflowY = "auto";
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  &.db,
  &.db2 {
    padding-bottom: 74px;
  }
  &.db {
    padding-top: 20vh;
  }

  &.db2 {
    padding-top: 20vh;
    display: block;
    height: calc(100% - 74px);
    padding-bottom: 0;
    z-index: 102;
    max-width: 1024px;
    margin-left: -512px;
    left: 50%;
  }
  .btn {
    min-width: 183px;
    width: auto;
  }
  .popupBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: rgba($color: #101623, $alpha: 0.3);
  }
  .popupContent {
    position: relative;
    padding: 0 24px;
    width: 100%;
    max-width: 1024px;
    //default popup
    &.agreeFail {
      .type1 {
        .title {
          color: #fc6969;
        }
        .txt {
          margin-top: 1px;
          color: #393a3e;
          text-align: center;
        }
      }
    }
    .type1 {
      display: flex;
      align-items: center;
      flex-direction: column;
      background: #fff;
      padding: 60px 28px 38px;
      border-radius: 24px;
      max-width: 327px;
      margin: auto;
      .btnWrap {
        width: 100%;
        margin-bottom: 0;
      }
      .title {
        display: block;
        @include boxTxtStyle($fz: 24px, $lh: 32px, $fw: 500, $c: #101623);
        &.imageFailTitle {
          color: #fc6969;
        }
      }

      .txt {
        @include boxTxtStyle($fz: 16px, $lh: 24px, $c: #a1a8b0, $ls: 0.5px);
        margin-bottom: 48px;
        &.imageFailTxt {
          text-align: center;
          color: #393a3e;
        }
        &.html {
          margin-top: 1px;
          text-align: center;
        }
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 102px;
        height: 102px;
        background: #f5f8ff;
        border-radius: 50%;
        margin-bottom: 40px;
      }
    }
    &.type2 {
      padding: 0 14px;
      max-width: 327px;
      margin: auto;
      .wrap {
        padding: 24px;
        background-color: #fff;
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
          0px 8px 8px -4px rgba(16, 24, 40, 0.03);
        border-radius: 12px;
        &.bgn {
          padding: 0;
          background: none;
          box-shadow: none;
        }
      }
    }

    .contentPopup {
      height: 100%;
      overflow-y: auto;
    }
    &.type3,
    &.type4 {
      padding: 0;
      height: 100%;
      overflow: hidden;
      background-color: #fff;
      border-radius: 30px 30px 0px 0px;
      margin: auto;
    }
  }
}

$mobile: 1024px;

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}

@include mobile {
  .popup.db2 {
    left: 0;
    margin-left: 0;
  }
}
</style>
