/* 상담관리 */
import { consult } from ".";

//상담 조회
function getConsultList(query) {
  return consult.get(`list${query}`);
}

//상담상세조회
function getConsultListDetail(id) {
  return consult.get(`/${id}`);
}

//상담 확정
function setConsult(id) {
  return consult.post(`/${id}/confirm`);
}

//매니저 등록
function setManager(obj) {
  return consult.post(`/${obj.id}/consult-manager`, obj.data);
}

//환자 상담 이력 조회
function getPatientList(query) {
  return consult.get(`/patient/history${query}`);
}

//반려사유
function setReject(obj) {
  return consult.put(`/${obj.booking_sid}/reject/${obj.query}`);
}

//반려사유 조회
function getReject(id) {
  return consult.get(`/reject/${id}`);
}

//상담 메모 조회
function getZoomMemo(obj) {
  return consult.get(`/${obj.booking_sid}/memo/${obj.scope}`);
}

//상담 메모 작성
function postZoomMemo(obj) {
  return consult.post(`/${obj.booking_sid}/memo/${obj.scope}`, obj.data);
}

//상담 메모 수정
function putZoomMemo(obj) {
  return consult.put(`/${obj.booking_sid}/memo/${obj.scope}`, obj.data);
}

export {
  getConsultList,
  getConsultListDetail,
  setConsult,
  getPatientList,
  setManager,
  getZoomMemo,
  postZoomMemo,
  putZoomMemo,
  setReject,
  getReject,
};
