const token = "healthOn-physician-token";
const user = "healthOn-physician-user";
const lang = "healthOn-lang";
const refresh = "healthOn-refresh";

function saveAuthToLocalStorage(value) {
  localStorage.setItem(token, value);
}

function saveUserToLocalStorage(value) {
  localStorage.setItem(user, value);
}

function saveRefreshToLocalStorage(value) {
  localStorage.setItem(refresh, value);
}

function saveLangLocalStorage(value) {
  localStorage.setItem(lang, value);
}

function getAuthFromLocalStorage() {
  return localStorage.getItem(token);
}

function getRefreshLocalStorage() {
  return localStorage.getItem(refresh);
}

function getUserFromLocalStorage() {
  return localStorage.getItem(user);
}

function getLangLocalStorage() {
  return localStorage.getItem(lang);
}

function deleteLocalStorage(value) {
  localStorage.removeItem(value);
}

function deleteAllLocalStorage() {
  localStorage.removeItem(user);
  localStorage.removeItem(token);
  localStorage.removeItem(refresh);
}

export {
  saveAuthToLocalStorage,
  saveUserToLocalStorage,
  getAuthFromLocalStorage,
  getUserFromLocalStorage,
  deleteLocalStorage,
  deleteAllLocalStorage,
  saveLangLocalStorage,
  getLangLocalStorage,
  saveRefreshToLocalStorage,
  getRefreshLocalStorage,
};
