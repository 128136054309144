import i18n from "@/i18n";
import store from "@/store";
import { createWebHistory, createRouter } from "vue-router";
const {
  global: { t },
} = i18n;

const router = createRouter({
  history: createWebHistory(),
  routes: [
    //splash screen
    {
      path: "",
      name: "Index",
      component: () => import("@/views/SplashScreenView"),
      meta: { homeTo: true },
    },
    //splash screen - login
    {
      path: "/onboarding",
      name: "onboarding",
      component: () => import("@/views/OnBoardingLoginView"),
      meta: { homeTo: true },
    },
    //login
    {
      path: "/login",
      name: "login",
      redirect: "/login/main",
      component: () => import("@/views/LoginView"),
      meta: { homeTo: true },
      children: [
        {
          path: "main",
          component: () => import("@/components/login/LoginContent.vue"),
        },
        {
          path: "pwfind",
          name: "pwfind",
          component: () =>
            import(
              "@/components/containers/myprofile/pwChange/EmailPwContainer.vue"
            ),
        },
        {
          path: "verify",
          name: "loginVerify",
          component: () =>
            import(
              "@/components/containers/myprofile/pwChange/CodePwContainer.vue"
            ),
          // eslint-disable-next-line
          beforeEnter: function (to, from, next) {
            if (store.state.email === null) {
              alert(t("common.expiration"));
              router.push("/login/pwfind");
            } else {
              next();
            }
          },
        },
        {
          path: "newpw",
          name: "loginNewPw",
          component: () =>
            import(
              "@/components/containers/myprofile/pwChange/NewPwContainer.vue"
            ),
        },
      ],
    },
    // join
    {
      path: "/join",
      name: "join",
      component: () => import("@/views/JoinView"),
      meta: { homeTo: true },
    },
    //home
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/HomeView"),
      meta: { authRequired: true },
    },
    //zoom
    {
      path: "/zoom",
      name: "zoom",
      component: () => import("@/views/ZoomView"),
      meta: { authRequired: true },
      children: [
        {
          path: "/zoom/:id",
          name: "zoomBooking",
        },
      ],
    },
    //zoom
    {
      path: "/voice",
      name: "voice",
      component: () => import("@/views/ZoomView"),
      meta: { authRequired: true },
      children: [
        {
          path: "/voice/:id",
          name: "voiceBooking",
        },
      ],
    },
    //metaverse
    {
      path: "/metaverse",
      name: "metaverse",
      component: () => import("@/views/MetaverseView"),
      meta: { authRequired: true },
      children: [
        {
          path: "/metaverse/:id",
          name: "metaverseBooking",
        },
      ],
    },
    //내 프로필
    {
      path: "/myprofile",
      name: "myprofile",
      component: () => import("@/views/myprofile/MyProfileView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "setting",
          name: "setting",
        },
      ],
    },
    //내 프로필 - 비밀번호 변경
    {
      path: "/myprofile/pwchange",
      name: "pwchange",
      component: () => import("@/views/myprofile/PwChangeView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/myprofile/pwchange",
          name: "currentpw",
          component: () =>
            import(
              "@/components/containers/myprofile/pwChange/CurrentPwContainer.vue"
            ),
        },
        {
          path: "email",
          name: "pwEmail",
          component: () =>
            import(
              "@/components/containers/myprofile/pwChange/EmailPwContainer.vue"
            ),
        },
        {
          path: "verify",
          name: "pwVerify",
          component: () =>
            import(
              "@/components/containers/myprofile/pwChange/CodePwContainer.vue"
            ),
          // eslint-disable-next-line
          beforeEnter: function (to, from, next) {
            if (store.state.email === null) {
              alert(t("common.expiration"));
              router.push("/myprofile/pwchange/email");
            } else {
              next();
            }
          },
        },
        {
          path: "newpw",
          name: "newPw",
          component: () =>
            import(
              "@/components/containers/myprofile/pwChange/NewPwContainer.vue"
            ),
        },
      ],
    },
    //내정보 관리 - zoom 연동
    {
      path: "/myprofile/zoomLinked",
      name: "zoomLinked",
      component: () => import("@/views/myprofile/ZoomLinkedView.vue"),
      meta: { authRequired: true },
    },

    {
      path: "/myprofile/zoomLinkedId",
      name: "zoomLinkedId",
      component: () => import("@/views/myprofile/ZoomLinkedView.vue"),
      meta: { authRequired: true },
    },
    //고객센터 - 공지사항
    {
      path: "/cs/notice",
      name: "notice",
      redirect: "/cs/notice/1",
      component: () => import("@/views/cs/NoticeView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/cs/notice/:id",
        },
      ],
    },
    //고객센터 - Faq
    {
      path: "/cs/faq",
      name: "faq",
      redirect: "/cs/faq/1",
      component: () => import("@/views/cs/FaqView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/cs/faq/:id",
        },
      ],
    },
    //상담관리 - 상담대기
    {
      path: "/dx/wait",
      name: "wait",
      redirect: "/dx/wait/list/1",
      component: () => import("@/views/dx/DxView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/dx/wait/list/:id",
          name: "waitList",
          component: () => import("@/components/dx/DxContent.vue"),
        },
        {
          path: "/dx/wait/list/:id/:detail",
          name: "waitDetail",
          component: () => import("@/components/dx/DxDetail.vue"),
        },
      ],
    },
    //상담관리 - 상담확정
    {
      path: "/dx/confirm",
      name: "confirm",
      redirect: "/dx/confirm/list/1",
      component: () => import("@/views/dx/DxView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/dx/confirm/list/:id",
          name: "confirmList",
          component: () => import("@/components/dx/DxContent.vue"),
        },
        {
          path: "/dx/confirm/list/:id/:detail",
          name: "confirmUserId",
          component: () => import("@/components/dx/DxDetail.vue"),
        },
      ],
    },
    //상담관리 - 상담완료
    {
      path: "/dx/completed",
      name: "completed",
      redirect: "/dx/completed/list/1",
      component: () => import("@/views/dx/DxView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/dx/completed/list/:id",
          name: "completedList",
          component: () => import("@/components/dx/DxContent.vue"),
        },
        {
          path: "/dx/completed/list/:id/:detail",
          name: "completedUserId",
          component: () => import("@/components/dx/DxDetail.vue"),
        },
      ],
    },
    //상담관리 - 상담반려
    {
      path: "/dx/reject",
      name: "reject",
      redirect: "/dx/reject/list/1",
      component: () => import("@/views/dx/DxView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/dx/reject/list/:id",
          name: "rejectList",
          component: () => import("@/components/dx/DxContent.vue"),
        },
        {
          path: "/dx/reject/list/:id/:detail",
          name: "rejectUserId",
          component: () => import("@/components/dx/DxDetail.vue"),
        },
      ],
    },
    //상담관리 - 환자 상담이력
    {
      path: "/dx/patient/list",
      name: "patient",
      component: () => import("@/views/dx/DxPatientView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/dx/patient/list/:id/:patient_sid",
          name: "patientList",
          component: () => import("@/components/dx/DxPatient.vue"),
        },
        {
          path: "/dx/patient/list/:id/:patient_sid/:detail",
          name: "patientDetail",
          component: () => import("@/components/dx/DxDetail.vue"),
        },
      ],
    },
    //스케줄 관리 - 스케줄 세팅
    {
      path: "/sked/setting",
      name: "skedSetting",
      component: () => import("@/views/sked/SkedSettingView.vue"),
      meta: { authRequired: true },
    },
    //스케줄 관리 - 예외일정 등록
    {
      path: "/sked/calendar",
      name: "skedCalendar",
      component: () => import("@/views/sked/SkedCalendarView.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/:pathMatch(.*)",
      name: "not-found",
      component: () => import("@/views/ErrorView"),
    },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(function (to, from, next) {
  if (store.state.error === -999) {
    // router.push("/error");
    store.state.error = null;
  }
  if (
    !store.state.token &&
    to.matched.some(function (routeInfo) {
      return routeInfo.meta.authRequired;
    })
  ) {
    // auth
    store.state.reLogin = true;
  } else {
    if (
      to.matched.some(function (routeInfo) {
        return routeInfo.meta.homeTo;
      }) &&
      store.state.token &&
      (store.state.error !== -801 ||
        store.state.error !== -802 ||
        store.state.error !== -803 ||
        store.state.error !== -804 ||
        store.state.error !== -805)
    ) {
      router.push("/home");
      if (store.state.reLogin) next();
    } else {
      if (
        store.state.token &&
        (store.state.error === -801 ||
          store.state.error === -802 ||
          store.state.error === -803 ||
          store.state.error === -804 ||
          store.state.error === -805)
      ) {
        next();
      }
      next();
    }
  }
});
export default router;
