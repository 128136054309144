export default {
  //token
  setUsername(state, username) {
    state.username = username;
  },
  clearUsername(state) {
    state.username = "";
  },
  setToken(state, token) {
    state.token = token;
  },
  clearToken(state) {
    state.token = "";
  },
  setNotice(state, notice) {
    state.cs.notice.list = notice.notice_list;
    state.cs.notice.total = notice.cri.total_count;
  },
  //home
  getHomeConfirmedCnt(state, cnt) {
    state.home.confirmed = cnt;
  },
  getHomeMyInfo(state, info) {
    state.home.myInfo = info;
  },
  getHomeReqCnt(state, cnt) {
    state.home.requested = cnt;
  },
};
