/* zoom */
import { zoom } from ".";

//zoom signature
function getZoomWebSdk(query) {
  return zoom.get(`meeting/web-sdk/parameter${query}`);
}

//zoom 연동
function getZoomConnect() {
  return zoom.get("connect");
}

//zoom 연동 아이디 조회
function getZoomUserId() {
  return zoom.get("user/id");
}

//zoom 연동
function deleteZoomUserId() {
  return zoom.delete("token?user_type=physician");
}

//진행중 미팅 조회
function getZoomStarted(meeting_number) {
  return zoom.get(`meeting/started${meeting_number}`);
}

//미팅 종료
function setZoomEnd(meeting_number) {
  return zoom.put(`/meeting/${meeting_number}/end`);
}

export {
  getZoomWebSdk,
  getZoomConnect,
  getZoomUserId,
  deleteZoomUserId,
  getZoomStarted,
  setZoomEnd,
};
