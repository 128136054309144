import { sked } from ".";

// 스케줄관리
// 조회
function getSkedTemp() {
  return sked.get(`/`);
}

// 세팅
function setSkedTemp(skedTemp) {
  return sked.post(`/`, skedTemp);
}

//예외 일자 등록

// 월 조회
function getCalender(date) {
  return sked.get(`/date${date}`);
}

// 월일 상세 조회
function getCalenderDetail(date) {
  return sked.get(`/date/${date}/activate`);
}

// 예외 일자 수정
function setCalenderDetail(id) {
  return sked.put(`/date`, id);
}

export {
  getSkedTemp,
  setSkedTemp,
  getCalender,
  getCalenderDetail,
  setCalenderDetail,
};
