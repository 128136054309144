import Vuex from "vuex";
import mutations from "@/store/mutations";
import actions from "@/store/acitons";
import {
  getAuthFromLocalStorage,
  getUserFromLocalStorage,
} from "@/utils/localStorage";

const store = new Vuex.Store({
  state: {
    username: getUserFromLocalStorage() || "",
    token: getAuthFromLocalStorage() || "",
    loading: false,
    reLogin: false,
    error: null,
    lang: null,
    email: null,
    emailToken: null,
    patient_name: null,
    home: {
      requested: "",
      confirmed: "",
      myInfo: "",
      notice: "",
    },
    zoom_account_yn: null,
    cs: {
      notice: {
        list: null,
        detail: null,
        total: null,
      },
      faq: {
        list: null,
        detail: null,
        total: null,
      },
    },
  },
  mutations,
  actions,
});

export default store;
