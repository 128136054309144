import ko from "@/locales/ko.json";
import en from "@/locales/en.json";
import ru from "@/locales/ru.json";

import { createI18n } from "vue-i18n";

const i18n = createI18n({
  locale: "ko",
  fallbackLocale: "en",
  messages: {
    en,
    ko,
    ru,
  },
});

export default i18n;
