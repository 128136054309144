<template>
  <span class="icon">
    <svg
      width="58"
      height="64"
      viewBox="0 0 58 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6486 2V10.5714M39.1351 2V10.5714M4 22.2571H51.7838M56 50.5714C56 52.7143 55.4097 54.7428 54.3697 56.4571C53.3788 58.15 51.9713 59.5513 50.2857 60.5232C48.6002 61.4951 46.6946 62.0041 44.7568 62C41.9178 62 39.3319 60.9428 37.3643 59.1428C36.493 58.4 35.7341 57.4857 35.1438 56.4571C34.0738 54.6869 33.5094 52.649 33.5135 50.5714C33.5135 44.2571 38.5449 39.1428 44.7568 39.1428C48.1297 39.1428 51.1373 40.6571 53.1892 43.0286C54.9969 45.112 55.9961 47.7934 56 50.5714Z"
        stroke="#199A8E"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40 49.9848L42.8558 53L49 47"
        stroke="#199A8E"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M53 21.0541V43.147C50.9317 40.8141 47.9 39.3243 44.5 39.3243C38.2383 39.3243 33.1667 44.3557 33.1667 50.5676C33.1667 52.6757 33.7617 54.6713 34.81 56.3578C35.405 57.3697 36.17 58.2692 37.0483 59H16.1667C6.25 59 2 53.3784 2 44.9459V21.0541C2 12.6216 6.25 7 16.1667 7H38.8333C48.75 7 53 12.6216 53 21.0541Z"
        stroke="#199A8E"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.9704 35H28M17 35H17.0296M17 43H17.0296"
        stroke="#199A8E"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style></style>
