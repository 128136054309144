import { createApp } from "vue";
import App from "./App";
import router from "./router/router";
import store from "./store";
import VueLoading from "vue-loading-overlay";
import VCalendar from "v-calendar";
import "vue-loading-overlay/dist/vue-loading.css";
import i18n from "@/i18n/index";
import { formatDate, formatTime } from "@/utils/filter";
import moment from "moment";
import dayjs from "dayjs";
const app = createApp(App);

app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$moment = moment;

window.app = app
  .use(i18n)
  .use(VCalendar)
  .use(router)
  .use(store)
  .use(VueLoading)
  .mount("#app");

app.config.globalProperties.$filters = {
  formatDate,
  formatTime,
};
