<template>
  <span class="icon">
    <svg
      width="58"
      height="64"
      viewBox="0 0 58 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2108 2V10.5263M38.9476 2V10.5263M3.42139 22.1505H51.7371M47.6728 53.4704L41.6476 47.4736M47.616 47.5304L41.5908 53.5557M44.6318 61.6841C47.6469 61.6841 50.5385 60.4863 52.6705 58.3543C54.8025 56.2223 56.0002 53.3308 56.0002 50.3157C56.0002 47.3006 54.8025 44.409 52.6705 42.277C50.5385 40.145 47.6469 38.9473 44.6318 38.9473C41.6167 38.9473 38.7251 40.145 36.5931 42.277C34.4612 44.409 33.2634 47.3006 33.2634 50.3157C33.2634 53.3308 34.4612 56.2223 36.5931 58.3543C38.7251 60.4863 41.6167 61.6841 44.6318 61.6841Z"
        stroke="#FC6969"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M53.1578 20.4736V42.8125C51.083 40.4536 48.042 38.9472 44.6315 38.9472C38.3504 38.9472 33.2631 44.0346 33.2631 50.3156C33.2631 52.4472 33.8599 54.4651 34.9115 56.1704C35.5083 57.1935 36.2757 58.103 37.1568 58.8419H16.2105C6.26315 58.8419 2 53.1577 2 44.6314V20.4736C2 11.9473 6.26315 6.26312 16.2105 6.26312H38.9473C48.8946 6.26312 53.1578 11.9473 53.1578 20.4736Z"
        stroke="#FC6969"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.565 35.2523H27.5934M17.0464 35.2523H17.0748M17.0464 43.7786H17.0748"
        stroke="#FC6969"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style></style>
