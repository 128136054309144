import { instance } from ".";

//회원가입
function registerUser(userData) {
  return instance.post("sign-up", userData);
}

//로그인
function loginUser(userData) {
  return instance.post("login", userData);
}

export { registerUser, loginUser };
