/* email */
import { emailCode } from ".";

//확인 코드
function checkCode(code) {
  return emailCode.post("/check-code/", code);
}

//이메일로 코드 전송
function sendCode(email) {
  return emailCode.post("/code/", email);
}

export { checkCode, sendCode };
