<template>
  <span class="icon">
    <svg width="60" height="34" viewBox="0 0 60 34" fill="none">
      <path
        d="M2 6.15384V19.0769C2 26 8 32 14.9231 32H32C34.3077 32 36.1538 30.1538 36.1538 27.8462V14.9231C36.1538 8 30.6154 2 23.2308 2H6.15385C3.84615 2 2 3.84615 2 6.15384Z"
        stroke="#FC6969"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M57.3801 3.84814V29.6943L44.457 20.9251V12.6174L57.3801 3.84814Z"
        stroke="#FC6969"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style></style>
