<template>
  <div class="spinner">
    <span v-for="item in 3" :key="item" class="ball"></span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  display: flex;
  span {
    margin: 7px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #e7faf8;
    transition: 0.25s ease;
    animation: bg 0.9s alternate infinite;
  }
}

@keyframes bg {
  from {
    background: $mainColor;
  }
  to {
    background: #e7faf8;
  }
}

@for $i from 1 through 13 {
  span {
    &:nth-child(#{$i}) {
      @if ($i == 1) {
        animation-delay: 0;
      } @else {
        animation-delay: ($i * 0.16s);
      }
    }
  }
}
</style>
