<template>
  <div class="loading">
    <Spinner />
  </div>
</template>

<script>
import Spinner from "./Spinner.vue";
export default {
  components: { Spinner },
  mounted() {
    document.body.style.overflowY = "hidden";
  },
  beforeUnmount() {
    document.body.style.overflowY = "auto";
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #101623, $alpha: 0.3);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
